import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  languageCode: string
  onClick: () => void
  variant?: Variant
}

export const ModalClose = memo(function ModalClose({
  languageCode,
  onClick,
  variant = 'default',
}: Props) {
  return (
    <Container dial={5} row onClick={onClick} variant={variant}>
      {variant !== 'compact' ? (
        <Label className="close-label">
          {useVocabularyData('close', languageCode)}
        </Label>
      ) : null}
      <Icon className="close-icon" />
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  cursor: pointer;
  position: fixed;
  top: 3.0625rem;
  right: 3.375rem;
  transition: 0.6s ease-in-out;
  z-index: 3;
  &:hover {
    .close-icon {
      &:before,
      &:after {
        height: 0.125rem;
        transform: none;
      }
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          top: 1.75rem;
          right: 1.75rem;
          .close-icon {
            &:before,
            &:after {
              background: ${theme.colors.variants.neutralDark4};
            }
          }
        `
      case 'dark':
        return css`
          .close-label {
            color: ${theme.colors.variants.neutralDark4};
          }
          .close-icon {
            &:before,
            &:after {
              background: ${theme.colors.variants.neutralDark4};
            }
          }
        `
    }
  }}

  @media (max-width: 767px) {
    top: 1.75rem;
    right: 1.75rem;
  }
`

const Icon = styled.div`
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 1.25rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 1.25rem;
    position: absolute;
    top: 0.5625rem;
    left: -0.0625rem;
    transition: 0.2s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
  margin-right: 1.25rem;

  @media (max-width: 1199px) {
    display: none !important;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'dark'
