import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import React, { memo, useEffect, useRef, useState } from 'react'
import { useContext } from 'react'

import { LanguageItem, Props as LanguageItemProps } from './LanguageItem'

export interface Props {
  className?: string
  languagesList: LanguageItemProps[]
  pageID?: string
}

export const Languages = memo(function Languages({
  className,
  languagesList,
  pageID,
}: Props) {
  const { languagePrefix } = useContext(HeaderContext) as any

  const languages = languagesList.filter((t) => t.pageID === pageID)

  const activeLanguage = useRef(null)
  const [othersLanguagesStatus, setOthersLanguagesStatus] = useState(false)

  const handleClickOutside = (e: any) => {
    // @ts-ignore
    if (activeLanguage.current && !activeLanguage.current.contains(e.target)) {
      setOthersLanguagesStatus(false)
    } else {
      // @ts-ignore
      if (othersLanguagesStatus) {
        setOthersLanguagesStatus(false)
      } else {
        setOthersLanguagesStatus(true)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [othersLanguagesStatus])

  return (
    <Container className={className} ref={activeLanguage}>
      <LanguageActive className={othersLanguagesStatus ? 'open' : undefined}>
        {languages
          .filter((t) => t.languagePrefix === languagePrefix)
          .map((item, index) => (
            <LanguageItem className="active" key={index} {...item} />
          ))}
      </LanguageActive>

      <OthersLanguages className={othersLanguagesStatus ? 'open' : undefined}>
        {languagesList
          .filter((t) => t.languagePrefix !== languagePrefix)
          .map((item, index) => (
            <LanguageItem key={index} {...item} />
          ))}
      </OthersLanguages>
    </Container>
  )
})

const Sticky = css`
  > div {
    &:first-of-type {
      a {
        color: ${theme.colors.variants.neutralDark2};
      }
    }
    &:last-of-type {
      top: 3.0625rem;
    }
    svg {
      stroke: ${theme.colors.variants.neutralDark2};
    }
  }
`

const Container = styled.div`
  margin-right: 2.5rem;
  position: relative;
  z-index: 3;
  &.sticky {
    ${Sticky}
  }

  @media (max-width: 1199px) {
    margin-right: 0;

    ${Sticky}
  }
`

const LanguageActive = styled.div`
  cursor: pointer;
  position: relative;
  transition: 0.3s ease-in-out;
  z-index: 3;
  &.open {
    svg {
      transform: scaleY(-1);
    }
  }
`

const OthersLanguages = styled.div`
  opacity: 0;
  position: absolute;
  top: 2.5rem;
  right: -0.5625rem;
  transform: translateY(1.25rem);
  transition: 0.3s ease-in-out;
  visibility: hidden;
  z-index: -1;
  &.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    z-index: 2;
  }

  a {
    width: 3.5rem;
    height: 3rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
  }

  @media (max-width: 1199px) {
    top: 2.75rem !important;
  }
`
