import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Bottom } from './Bottom'
import { Contacts } from './Contacts'
import { Props as MenuItemProps } from './MenuItem'
import { Social } from './Social'

export interface Props {
  address?: string
  email?: string
  facebookURL?: string
  fax?: string
  instagramURL?: string
  youtubeURL?: string
  languageCode: string
  logo?: string
  phone?: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  shareCapital?: string
  siteName?: string
  vat?: string
  whatsappNumber?: string
  whatsappURL?: string
}

export const Footer = memo(function Footer({
  address,
  email,
  facebookURL,
  fax,
  instagramURL,
  youtubeURL,
  languageCode,
  logo,
  phone,
  policyMenu,
  shareCapital,
  siteName,
  vat,
  whatsappNumber,
  whatsappURL,
}: Props) {
  return (
    <Container>
      <Wrapper row space="between" wrap>
        <Cell>
          {logo ? (
            <Logo alt={siteName} src={logo} width="114" height="80" />
          ) : null}
        </Cell>
        <Cell>
          <Label>{siteName}</Label>
          {address ? (
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          ) : null}

          <Social
            facebookURL={facebookURL}
            instagramURL={instagramURL}
            youtubeURL={youtubeURL}
          />
        </Cell>
        <Cell>
          <Label>{useVocabularyData('contacts', languageCode)}</Label>
          <Contacts
            email={email}
            fax={fax}
            languageCode={languageCode}
            phone={phone}
            whatsappNumber={whatsappNumber}
            whatsappURL={whatsappURL}
          />
        </Cell>
      </Wrapper>

      <Bottom
        languageCode={languageCode}
        policyMenu={policyMenu}
        shareCapital={shareCapital}
        vat={vat}
      />
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  overflow: hidden;
  padding: 6.25rem 10.556vw 2.625rem;

  @media (max-width: 1199px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-right: -2.125rem;

  @media (max-width: 1023px) {
    margin-right: 0;
    text-align: center;
  }
`

const Cell = styled.div`
  width: calc(33.333% - 2.125rem);
  margin-right: 2.125rem;

  @media (max-width: 1023px) {
    width: 100%;
    margin-right: 0;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 1.75rem;
  &:after {
    content: '';
    display: block;
    width: 2rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-top: 1.125rem;
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
  }

  @media (max-width: 1023px) {
    &:after {
      margin-right: auto;
      margin-left: auto;
    }
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`

const Logo = styled.img`
  @media (max-width: 1023px) {
    margin-bottom: 2.5rem;
  }
`
