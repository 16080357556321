import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  email?: string
  fax?: string
  languageCode: string
  phone?: string
  whatsappNumber?: string
  whatsappURL?: string
}

export const Contacts = memo(function Contacts({
  email,
  fax,
  languageCode,
  phone,
  whatsappNumber,
  whatsappURL,
}: Props) {
  return (
    <Container>
      {phone ? (
        <Anchor
          href={`tel:${phone}`}
          onClick={() => {
            if (isMobile) {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Phone Number',
                })
            }
          }}
        >
          {`${useVocabularyData('ph', languageCode)}: ${phone}`}
        </Anchor>
      ) : null}
      {whatsappNumber ? (
        <>
          <Divider />
          <Anchor aria-label="WhatsApp" href={whatsappURL}>
            {`${useVocabularyData(
              'whatsapp',
              languageCode,
            )}: ${whatsappNumber}`}
          </Anchor>
        </>
      ) : null}
      {fax ? (
        <Fax>{`${useVocabularyData('fax', languageCode)}: ${fax}`}</Fax>
      ) : null}
      {email ? (
        <>
          <Divider />
          <Anchor
            href={`mailto:${email}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'Click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })
            }}
          >
            {`${useVocabularyData('email', languageCode)}: ${email}`}
          </Anchor>
        </>
      ) : null}
    </Container>
  )
})

const Container = styled.div``

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  &:hover {
    text-decoration: underline;
  }
`

const Fax = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`

const Divider = styled.div``
