import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'

export interface Props {
  alt?: string
  className?: string
  decoding?: 'auto' | 'async'
  height?: number
  media?: string
  sources?: string
  src?: string
  srcSet?: string
  width?: number
}

export const Image = memo(function Image({
  alt,
  className,
  decoding,
  height,
  media,
  sources,
  src,
  srcSet,
  width,
}: Props) {
  return (
    <Picture className={className}>
      <Source srcSet={sources} media={media || undefined} />
      <Img
        alt={alt}
        decoding={decoding}
        height={height}
        src={src}
        srcSet={srcSet}
        width={width}
        initial={{ filter: 'blur(8px)', opacity: 0 }}
        animate={{
          filter: 'blur(0)',
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
      />
    </Picture>
  )
})

const Picture = styled.picture``

const Source = styled.source``

const Img = styled(motion.img)``
