import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  URL: string
}

export const MenuItem = memo(function MenuItem({ title, URL }: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container activeClassName="active" to={URL}>
      {title}
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: '';
    width: 0.0625rem;
    height: 1.25rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    margin: 0 1.5rem;
  }

  @media (max-width: 1023px) {
    justify-content: center;
    &:before {
      display: none;
    }
  }
`
