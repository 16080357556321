import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  className?: string
}

export const Line = memo(function Line({ className }: Props) {
  return <Container className={className} />
})

const Container = styled.div`
  width: 3.9375rem;
  height: 0.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-top: 1.25rem;
`
