import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Dropdown, Dropup } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme/index'
import { AnimatePresence, motion } from 'framer-motion'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  childs?: ChildsProps[]
  className?: string
  id?: string
  title: string
  languageCode?: string
  languagePrefix?: string | null
  modalStatus?: boolean
  url: string
}

interface ChildsProps {
  id?: string
  modalStatus?: boolean
  title: string
  url: string
}

export const MenuItem = memo(function MenuItem({
  childs,
  className,
  modalStatus,
  title,
  url,
}: Props) {
  if (!title) {
    return null
  }

  const [submenuVisibility, setSubmenuVisibility] = useState(false)

  useEffect(() => {
    setSubmenuVisibility(false)
  }, [])

  return (
    <>
      {url ? (
        <Anchor className={className} to={url} activeClassName="active">
          {title}
        </Anchor>
      ) : (
        <NoLink
          dial={4}
          className={submenuVisibility ? 'open' : undefined}
          inline
          onClick={() =>
            submenuVisibility
              ? setSubmenuVisibility(false)
              : setSubmenuVisibility(true)
          }
          row
          tag="span"
        >
          {title}
          {submenuVisibility ? <Dropup /> : <Dropdown />}
        </NoLink>
      )}
      {childs && childs.length > 0 ? (
        <AnimatePresence>
          {submenuVisibility && (
            <Submenu
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: 'auto',
                marginTop: 8,
                marginBottom: 30,
              }}
              exit={{ opacity: 0, height: 0, marginTop: 0, marginBottom: 0 }}
              transition={{ duration: 0.3 }}
            >
              {childs
                .sort((a: any, b: any) => a.weight - b.weight)
                .map((item, index) => (
                  <ChildItem key={index} modalStatus={modalStatus} {...item} />
                ))}
            </Submenu>
          )}
        </AnimatePresence>
      ) : null}
    </>
  )
})

export const ChildItem = memo(function ChildItem({ title, url }: ChildsProps) {
  return (
    <SubmenuLi>
      <Anchor to={url} activeClassName="active">
        {title}
      </Anchor>
    </SubmenuLi>
  )
})

const Style = css`
  display: inline-block;
  color: ${theme.colors.variants.neutralLight4};
  font-family: ${theme.fontFamily.heading};
  font-size: 4vh;
  font-weight: 500;
  line-height: 5.1vh;
  opacity: 0.3;
  transition: 0.2s ease-out;
  &.active {
    opacity: 1;
    &:hover {
      opacity: 1;
    }
  }
  &:hover {
    opacity: 0.5;
  }
`

const Anchor = styled(Link)`
  ${Style}
`

const NoLink = styled(FlexBox)`
  cursor: pointer;
  position: relative;
  &.open {
    opacity: 1;
    &:hover {
      opacity: 1;
    }
  }

  svg {
    width: 1rem;
    height: auto;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 2;
    position: absolute;
    right: 0;
    bottom: 50%;
    transform: translate(2rem, 0.5rem);
  }

  ${Style}
`

const Submenu = styled(motion.ul)``

const SubmenuLi = styled.li`
  margin-top: 0 !important;
  a {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    font-size: 1.9vh;
    font-family: 400;
    line-height: 4vh;
  }
`
