import styled from '@emotion/styled'
import { Facebook, Instagram, Youtube } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  facebookURL?: string
  instagramURL?: string
  youtubeURL?: string
}

export const Social = memo(function Social({
  facebookURL,
  instagramURL,
  youtubeURL,
}: Props) {
  if (!facebookURL && !instagramURL && !youtubeURL) {
    return null
  }

  return (
    <Container dial={4} row wrap>
      {facebookURL ? (
        <Anchor
          aria-label="Facebook"
          href={facebookURL}
          rel="noreferrer"
          target="_blank"
        >
          <Facebook />
        </Anchor>
      ) : null}
      {instagramURL ? (
        <Anchor
          aria-label="Instagram"
          href={instagramURL}
          rel="noreferrer"
          target="_blank"
        >
          <Instagram />
        </Anchor>
      ) : null}
      {youtubeURL ? (
        <Anchor
          aria-label="YouTube"
          className="youtube"
          href={youtubeURL}
          rel="noreferrer"
          target="_blank"
        >
          <Youtube />
        </Anchor>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 2.25rem;

  @media (max-width: 1023px) {
    justify-content: center;
    margin: 3rem auto;
  }
`

const Anchor = styled.a`
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    }
  }
  &.youtube {
    svg {
      height: 1.625rem;
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 1.5rem;
    transition: 0.15s ease-in-out;
  }

  @media (max-width: 1023px) {
    svg {
      margin: 0 0.75rem;
    }
  }
`
