import styled from '@emotion/styled'
import { HeaderContext } from 'app/containers/Header'
import { useVocabularyData } from 'app/utils/vocabulary'
import { motion } from 'framer-motion'
import React, { memo, useContext } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  menuItems: MenuItemProps[]
  modalStatus?: boolean
}

export const Navigation = memo(function Navigation({
  menuItems,
  modalStatus,
}: Props) {
  const { IBEURL, languageCode, languagePrefix, logo, siteName } = useContext(
    HeaderContext,
  ) as any

  const variants = {
    visible: (i: any) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        stiffness: 1000,
        velocity: -100,
      },
    }),
    hidden: { opacity: 0, y: -60 },
  }

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <Logo src={logo} alt={siteName} width="114" height="80" />

      {menuItems ? (
        <Nav>
          <NavList>
            {menuItems.map((item, index) => (
              <motion.li
                key={index}
                custom={index}
                animate={modalStatus ? 'visible' : 'hidden'}
                variants={variants}
              >
                <MenuItem modalStatus={modalStatus} {...item} />
              </motion.li>
            ))}
            {IBEURL ? (
              <motion.li
                custom={menuItems.length + 1}
                animate={modalStatus ? 'visible' : 'hidden'}
                variants={variants}
              >
                <Anchor
                  href={`${IBEURL}&lang=${languagePrefix || 'it'}`}
                  rel="noopener"
                  target="_blank"
                >
                  {useVocabularyData('book', languageCode)}
                </Anchor>
              </motion.li>
            ) : null}
          </NavList>
        </Nav>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: auto;
`

const Logo = styled.img`
  position: fixed;
  top: 1rem;
  left: 3rem;

  @media (max-width: 767px) {
    display: none;
  }
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem;
  position: absolute;
  top: 20vh;
  right: 1.875rem;
  left: 1.875rem;

  @media (max-width: 767px) {
    top: 6.25rem;
    right: 1.25rem;
    left: 1.25rem;
  }
`

const NavList = styled.ul`
  width: 100%;
  text-align: center;

  li {
    margin-top: 0.875rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4vh;
  font-weight: 500;
  line-height: 5.1vh;
  opacity: 0.3;
  transition: 0.2s ease-out;
  &:hover {
    opacity: 0.5;
  }
`
