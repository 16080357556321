import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { compact, uniqBy } from 'lodash'
import React, { memo } from 'react'

import { MenuItem, Props as MenuItemProps } from './MenuItem'

export interface Props {
  languageCode: string
  policyMenu?: { [key: string]: MenuItemProps[] }[]
  shareCapital?: string
  vat?: string
}

export const Bottom = memo(function Bottom({
  languageCode,
  policyMenu,
  shareCapital,
  vat,
}: Props) {
  const policyNav = compact(
    compact(policyMenu?.map((lang) => lang[languageCode as 'it-IT']))[0],
  ).filter((t) => t.languageCode === languageCode)

  return (
    <Container dial={4} row wrap>
      <Wrapper row wrap>
        <Copy>© {new Date().getFullYear()} LOS NIDOS S.R.L.</Copy>
        {vat ? (
          <Vat>{`${useVocabularyData('vat', languageCode)} ${vat}`}</Vat>
        ) : null}
        {shareCapital ? (
          <ShareCapital>{`${useVocabularyData(
            'share-capital',
            languageCode,
          )} ${shareCapital}`}</ShareCapital>
        ) : null}
      </Wrapper>

      {policyMenu ? (
        <Policy dial={2} row wrap>
          {uniqBy(policyNav, 'title').map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </Policy>
      ) : null}

      <Credits
        aria-label="QNT Hospitality"
        href="https://www.qnt.it"
        rel="noopenet"
        target="_blank"
      >
        QNT Hospitality
      </Credits>
    </Container>
  )
})

const Container = styled(FlexBox)`
  border-top: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralDark3};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-top: 3.75rem;
  padding-top: 2.125rem;

  @media (max-width: 1023px) {
    justify-content: center;
  }
`

const Wrapper = styled(FlexBox)`
  > div {
    margin-right: 0.1875rem;
    &:last-of-type {
      margin-right: 0;
      &:after {
        display: none;
      }
    }
    &:after {
      content: ' - ';
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: center;

    > div {
      width: 100%;
      margin-right: 0;
      text-align: center;
      &:after {
        content: '';
        display: block;
      }
    }
  }
`

const Copy = styled.div``

const Vat = styled.div``

const ShareCapital = styled.div``

const Policy = styled(FlexBox)`
  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 1rem;
  }
`

const Credits = styled.a`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-weight: 500;
  margin-left: auto;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1023px) {
    margin: 1rem auto 0;
  }
`
